<template>
  <b-container class="iv-form">
    <b-row class="justify-content-center">
      <b-col lg="8" class="iv-form__block">
        <div v-if="form.image_url" class="text-center my-3">
          <img :src="form.image_url" class="iv-form__img" />
        </div>
        <h1
          v-if="!$store.state.disableHeadline"
          class="h4 my-5 text-center text-pre"
        >
          {{ form.title }}
        </h1>
        <p
          v-if="form.header_text && openType && summary"
          v-text="form.header_text"
          class="iv-form__header-text text-pre mb-5"
        ></p>
        <div v-if="openType && formActive" class="iv-form__content">
          <router-view
            :items="items"
            :answers="answers"
            :display-type="displayType"
            :message="form.complete_text"
            @set-answers="setAnswers"
            @reset-answers="resetAnswers"
          ></router-view>
        </div>
        <div v-else-if="openType === false" class="iv-form__content">
          <hr />
          <p class="iv-form__outside-text text-center text-pre">
            {{ form.outside_text }}
          </p>
          <hr />
        </div>
        <div v-if="form.footer_text || form.copyright" class="my-5">
          <!-- <p
            v-if="openType && summary"
            v-text="form.footer_text"
            class="iv-form__footer-text text-pre mb-5"
          ></p>
          <p class="text-center">
            <small class="iv-form__copyright">{{ form.copyright }}</small>
          </p> -->
          <p
            v-if="openType && summary"
            class="iv-form__footer-text text-pre mb-5"
          >
            Powered by
            <a href="//ivalue.jp" target="_blank">
              <img
                src="//survey.sendai-airport.ivalue.info/entrance/img/ivalue_logo_05x.png"
                alt="ivalue"
                class="iv-form__logo-ivalue"
              />
            </a>
          </p>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      answers: {},
      form: {},
      items: [],
      openType: "",
      formActive: false,
      displayType: "",
      summary: false,
    };
  },

  watch: {
    $route(to, from) {
      if (to.name !== from.name) {
        if (to.name == "form") {
          this.queryCheck();
        }
        this.routeName();
      }
    },
  },

  created() {
    const query = this.$route.query;
    let existsRemoveUrl = false;

    if (Object.keys(query).length > 0) {
      Object.keys(query).forEach((e) => {
        // hiddenタイプの設問回答取得
        if (!e.indexOf("params[")) {
          this.$store.dispatch("setParams", { hidden: query });
        }

        // 表示様式を制御するオプション取得
        if (e === "disableHeadline" && query[e] == 1) {
          this.$store.dispatch("disableHeadline");
        }

        // 本サイトに戻る、などの遷移ボタンを設置するパラメータ取得
        if (e === "removeUrl" && query[e] !== "") {
          this.$store.dispatch("removeUrl", { url: query[e] });
          existsRemoveUrl = true;
        }
      });
    }

    // 読み込み防止処理
    if (existsRemoveUrl === false) {
      window.addEventListener("beforeunload", function (e) {
        var confirmationMessage = "入力内容を破棄します。";
        e.returnValue = confirmationMessage;
        return confirmationMessage;
      });
    }

    const appId = this.$route.params.app_id;
    const formId = this.$route.params.form_id;
    this.$store.dispatch("setInitialId", { app: appId, form: formId });

    const self = this;

    // フォームデータの取得
    const FormRepository = self.$repository.get("form");
    const form = FormRepository.index();
    form.then(
      function (result) {
        const res = result.data.response.data;
        self.form = res;
        self.displayType = res.type;
        if (self.form.is_open === 1) {
          self.openType = true;
          self.getItems();
        } else if (self.form.is_open === 0) {
          self.$router.push("/404");
        } else {
          self.openCheck();
        }

        // タイトルを動的表示
        document.title = res.title;

        if (self.form.zipserver != null) {
          self.$store.dispatch("setZipserver", {
            use: self.form.zipserver.is_use,
            key: self.form.zipserver.api_key,
          });
        }
      },
      function (error) {
        const res = error.response;
        self.$router.push("/" + res.status);
      }
    );

    // マスターデータの取得
    const localData = JSON.parse(localStorage.getItem("iv_form_update_at"));
    const MasterRepository = self.$repository.get("master");
    const master = MasterRepository.index();
    master.then(
      function (result) {
        const res = result.data.response.data;
        const update = self.$moment(res.updated_at).isAfter(localData);
        if (!localData || update) {
          localStorage.setItem(
            "iv_mst_prefectures",
            JSON.stringify(res.prefecture)
          );
          localStorage.setItem(
            "iv_form_update_at",
            JSON.stringify(res.updated_at)
          );
        }
      },
      function (error) {
        const res = error.response;
        self.$router.push("/" + res.status);
      }
    );
  },

  mounted() {
    if (this.$route.name == "form") {
      this.queryCheck();
    }
    this.routeName();
  },

  methods: {
    // 設問一覧の取得
    getItems() {
      const self = this;
      const ItemRepository = self.$repository.get("item");
      const item = ItemRepository.index();
      item.then(
        function (result) {
          self.items = result.data.response.data;
          for (let e of self.items) {
            if (e.type == "hidden") {
              const query = self.$route.query;
              if (Object.keys(query).length === 0) {
                self.$router.push("/404");
              } else {
                const params = Object.keys(query);
                let existsParams = false;

                Object.keys(params).forEach((e) => {
                  // hiddenタイプの設問回答取得
                  if (e.indexOf("params[")) {
                    existsParams = true;
                  }
                });

                if (existsParams === false) {
                  self.$router.push("/404");
                }
              }
              break;
            }
          }

          self.items.forEach(function (v) {
            if (v.type == "checkbox") {
              self.$set(v, "value", []);
            } else if (v.type == "range") {
              self.$set(v, "value", "0");
            } else {
              self.$set(v, "value", "");
            }
          });
          self.formActive = true;
          if (self.$route.name == "form") {
            self.queryCheck();
          }
        },
        function (error) {
          const res = error.response;
          self.$router.push("/" + res.status);
        }
      );
    },

    openCheck() {
      const start = this.form.open_start_date;
      const end = this.form.open_end_date;
      if (this.$moment().isAfter(start) && this.$moment().isBefore(end)) {
        this.openType = true;
        this.formActive = true;
        this.getItems();
      } else {
        this.openType = false;
      }
    },

    queryCheck() {
      if (this.displayType == "step_form") {
        const confirm = this.$store.state.confirm;
        var result;
        for (var i = 0; i < this.items.length; i++) {
          if (this.items[i].is_hide === 0) {
            result = this.items[i].id;
            break;
          }
        }
        const id = result;
        if (!this.$route.query.id) {
          this.$router.push({
            query: Object.assign({}, this.$route.query, { id: id }),
          });
        } else if (!(this.$route.query.id == id) && !confirm) {
          this.$router.push({
            query: Object.assign({}, this.$route.query, { id: id }),
          });
        }
      }
    },

    routeName() {
      const route = this.$route.name;
      route == "form" || route == "confirm"
        ? (this.summary = true)
        : (this.summary = false);
    },

    // 回答の値をセット
    setAnswers() {
      const params = this.$store.state.params;
      const self = this;
      self.items.forEach(function (v) {
        if (v.type == "hidden") {
          if (params) {
            const value = params[`params[${v.id}]`];
            value
              ? self.$set(self.answers, v.id, value)
              : self.$set(self.answers, v.id, "");
          } else {
            self.$set(self.answers, v.id, "");
          }
        } else if (v.type !== "comment") {
          self.$set(self.answers, v.id, v.value);
        }
      });
      // console.log(this.answers);
      this.$router.push({
        name: "confirm",
        query: Object.assign({}, this.$route.query),
      });
    },

    resetAnswers() {
      this.answers = {};
      this.getItems();
      this.$router.push({ name: "form" });
    },
  },
};
</script>

<style lang="scss">
.iv-form__logo-ivalue {
  margin-bottom: 8px;
  height: 20px;
  width: auto;
}
</style>
