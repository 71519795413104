import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize,
} from "vee-validate";
import ja from "vee-validate/dist/locale/ja.json";
import en from "vee-validate/dist/locale/en.json";
import * as rules from "vee-validate/dist/rules";

// フォームIDをキーに日本語か英語かを切り替える
let langVariation = ja;
let langKeyName = "ja";
let required = "この項目は必須項目です。";
let notFormat = "有効な形式ではありません。";

const formId = Number(location.href.split("/")[4]);

if (
  formId !== Number(process.env.VUE_APP_JP_COMMON_FORM_ID) &&
  formId !== Number(process.env.VUE_APP_JP_INTERNATIONAL_FORM_ID) &&
  formId !== Number(process.env.VUE_APP_JP_DOMESTIC_FORM_ID)
) {
  // 英語表記に切り替え
  langVariation = en;
  langKeyName = "en";
  required = notFormat = "There is an error in the input.";
}

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

extend("tel", {
  message: notFormat,
  validate(value) {
    if (
      value.match(/^0[1-9][0-9]{0,4}-[0-9]{1,5}-[0-9]{1,5}$/) ||
      value.match(/^0[0-9]{8,10}$/)
    ) {
      return true;
    }
  },
});

extend("zip", {
  message: notFormat,
  validate(value) {
    if (
      value.match(/^[0-9]{3}[-][0-9]{4}$/) ||
      value.match(/^[0-9]{3}[0-9]{4}$/)
    ) {
      return true;
    }
  },
});

localize(langKeyName, langVariation);

localize({
  [langKeyName]: {
    messages: {
      required: required,
      email: notFormat,
    },
  },
});

const GlobalValidation = {
  install(app) {
    app.component("ValidationObserver", ValidationObserver);
    app.component("ValidationProvider", ValidationProvider);
  },
};

export default GlobalValidation;
