<template>
  <div class="text-center">
    <b-button
      @click="useCoupon"
      :disabled="isUse"
      class="iv-btn iv-btn__submit"
    >
      <span v-if="lang === 'ja'">クーポンを利用する</span>
      <span v-else-if="lang === 'en'">Use a Coupon</span>
    </b-button>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    answers: Object,
  },

  data() {
    return {
      isUse: false,
      lang: "ja",
    };
  },

  created() {
    const formId = Number(location.href.split("/")[4]);

    this.lang =
      formId === Number(process.env.VUE_APP_JP_COMMON_FORM_ID) ||
      formId === Number(process.env.VUE_APP_JP_INTERNATIONAL_FORM_ID) ||
      formId === Number(process.env.VUE_APP_JP_DOMESTIC_FORM_ID)
        ? "ja"
        : "en";
  },

  methods: {
    async useCoupon() {
      const self = this;
      self.isUse = true;

      const api = axios.create({
        baseURL: process.env.VUE_APP_COUPON_API_BASEURL,
        headers: {
          "Content-Type": "application/json",
        },
        responseType: "json",
      });

      try {
        api
          .post("/generate-token")
          .then((response) => {
            if (response && response.data && response.data.token) {
              console.log("Generated Token.");

              self.sendToCoupon(response.data.token);
            } else {
              console.warn("Token Error.");
              console.log(response);
              self.viewSwal();
            }
          })
          .catch((error) => {
            console.warn(error);
            self.viewSwal();
          });
      } catch (error) {
        console.warn(error);
        self.viewSwal();
      }
    },

    async sendToCoupon(token = "") {
      const self = this;
      const d = new Date();
      const answerId = Math.floor(d.getTime() / 1000);
      const itemId = self.setItemId();

      const api = axios.create({
        baseURL: process.env.VUE_APP_COUPON_API_BASEURL,
        headers: {
          "Content-Type": "application/json",
          authorization: process.env.VUE_APP_COUPON_API_CLIENT_KEY,
        },
        responseType: "json",
      });

      try {
        api
          .post("/update-coupon-data", {
            token: token,
            answer_date:
              d.getFullYear() +
              "-" +
              (d.getMonth() + 1).toString().padStart(2, "0") +
              "-" +
              d.getDate().toString().padStart(2, "0"),
            answer_id: answerId,
            birth_ym: self.answers[itemId],
          })
          .then((response) => {
            if (response && response.status && response.status === 200) {
              console.log("Updated.");

              const form = document.createElement("form");
              form.method = "get";
              form.action =
                process.env.VUE_APP_COUPON_SYS_BASEURL +
                "/coupon/show/" +
                token;
              document.body.append(form);
              form.submit();
            } else {
              console.warn("Update Error.");
              console.log(response);
              self.viewSwal();
            }
          })
          .catch((error) => {
            console.warn(error);
            self.viewSwal();
          });
      } catch (error) {
        console.warn(error);
        self.viewSwal();
      }
    },

    setItemId() {
      const formId = Number(location.href.split("/")[4]);
      let itemId = 0;

      switch (formId) {
        case Number(process.env.VUE_APP_JP_COMMON_FORM_ID):
          itemId = Number(process.env.VUE_APP_JP_COMMON_FORM_IN_BIRTH_ITEM_ID);
          break;
        case Number(process.env.VUE_APP_JP_INTERNATIONAL_FORM_ID):
          itemId = Number(
            process.env.VUE_APP_JP_INTERNATIONAL_FORM_IN_BIRTH_ITEM_ID
          );
          break;
        case Number(process.env.VUE_APP_JP_DOMESTIC_FORM_ID):
          itemId = Number(
            process.env.VUE_APP_JP_DOMESTIC_FORM_IN_BIRTH_ITEM_ID
          );
          break;
        case Number(process.env.VUE_APP_EN_FORM_ID):
          itemId = Number(process.env.VUE_APP_EN_FORM_IN_BIRTH_ITEM_ID);
          break;
        case Number(process.env.VUE_APP_KR_FORM_ID):
          itemId = Number(process.env.VUE_APP_KR_FORM_IN_BIRTH_ITEM_ID);
          break;
        case Number(process.env.VUE_APP_CHS_FORM_ID):
          itemId = Number(process.env.VUE_APP_CHS_FORM_IN_BIRTH_ITEM_ID);
          break;
        case Number(process.env.VUE_APP_CHT_FORM_ID):
          itemId = Number(process.env.VUE_APP_CHT_FORM_IN_BIRTH_ITEM_ID);
          break;
      }

      return itemId;
    },

    viewSwal() {
      this.$swal({
        icon: "error",
        title: this.$t("form.send_error"),
        text: this.$t("form.send_error_message"),
      });
    },
  },
};
</script>

<style lang="scss"></style>
