export default {
  ja: {
    form: {
      required: "必須",
      next: "次へ",
      back: "戻る",
      confirm: "入力内容の確認",
      submit: "入力内容の送信",
      return_link: "変更",
      unanswered: "未回答",
      date_selected: "日付を選択してください",
      address_title: "住所選択",
      selected: "選択",
      selected_message: "選択してください。",
      search: "検索",
      not_search: "住所が見つかりません",
      not_search_message: "指定された郵便番号から住所が見つかりませんでした。",
      address_error: "取得に失敗しました",
      send_error: "送信に失敗しました",
      send_error_message: "時間をおいて再送信してください。",
    },
    error: {
      404: "Not Found",
      500: "Internal Server Error",
      502: "Bad Gateway",
      503: "Service Unavailable",
    },
  },
  en: {
    form: {
      required: "Required",
      next: "Next",
      back: "Back",
      confirm: "Confirmation",
      submit: "Send",
      return_link: "Edit",
      unanswered: "Not filled in",
      date_selected: "Please choose a date.",
      address_title: "Address select",
      selected: "select",
      selected_message: "Please select.",
      search: "Search",
      not_search: "Address not found.",
      not_search_message:
        "No address was found from the specified postal code.",
      address_error: "Failed to get.",
      send_error: "Send failed.",
      send_error_message: "Please wait a while and resend.",
    },
    error: {
      404: "Not Found",
      500: "Internal Server Error",
      502: "Bad Gateway",
      503: "Service Unavailable",
    },
  },
};
